<script>
import { getProductAnalytics } from "@/api/analytics";
import { format, setDate } from "date-fns";

export default {
  name: "Products",
  data() {
    return {
      products: [],
      productsCount: 0,
      filters: {
        start_date: null,
        stop_date: null,
        query: "",
      },
    };
  },
  computed: {
    filterDate: {
      get() {
        if (this.filters.start_date && this.filters.stop_date) {
          return [this.filters.start_date, this.filters.stop_date];
        }
        return [];
      },
      set(val) {
        if (val) {
          this.filters.start_date = val[0];
          this.filters.stop_date = val[1];
        } else {
          this.filters.start_date = null;
          this.filters.stop_date = null;
        }
      },
    },
    filteredProducts() {
      return this.products.filter(
        (product) =>
          product.product_name &&
          product.product_name
            .toLowerCase()
            .includes(this.filters.query.toLowerCase())
      );
    },
  },
  mounted() {
    // default this months, date format is YYYY-MM-DD, use date-fns
    const start_date = format(setDate(new Date(), 1), "yyyy-MM-dd");
    const stop_date = format(new Date(), "yyyy-MM-dd");
    this.filters.start_date = start_date;
    this.filters.stop_date = stop_date;
    this.handleFiltersChange();
  },
  methods: {
    handleFiltersChange() {
      getProductAnalytics(this.filters).then(({ products }) => {
        this.products = products;
        this.productsCount = products.reduce(
          (acc, product) => acc + product.count,
          0
        );
      });
    },
    downloadAsFile() {
      const params = new URLSearchParams({
        ...this.filters,
        action: "download",
      }).toString();

      const DOMAIN = process.env["VUE_APP_DOMAIN"];
      window.open(`${DOMAIN}/api/analytics/products?${params}`, "_blank");
    },
  },
};
</script>

<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="font-medium text-lg">
        Product Analytics ({{ productsCount }})
      </div>
      <div class="flex items-center gap-x-2">
        <el-input
          size="small"
          v-model="filters.query"
          placeholder="Поиск"
          clearable
        ></el-input>
        <el-date-picker
          v-model="filterDate"
          @change="handleFiltersChange"
          type="daterange"
          size="small"
          range-separator=" - "
          start-placeholder="Дата с"
          end-placeholder="Дата по"
          value-format="yyyy-MM-dd"
          class="flex-shrink-0"
        >
        </el-date-picker>
        <el-button @click="downloadAsFile" size="small" type="success"
          >Download</el-button
        >
      </div>
    </div>
    <div class="mt-5">
      <el-table :data="filteredProducts" border size="small">
        <el-table-column type="index" align="center"></el-table-column>
        <el-table-column
          prop="product_id"
          label="Product ID"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="product_name"
          label="Product Name"
        ></el-table-column>
        <el-table-column
          width="200"
          prop="count"
          align="right"
          label="Total"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<style scoped></style>
